<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import { UsersIcon } from "@heroicons/vue/outline";

import { useCookies } from "vue3-cookies";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useAuthStore } from "@/stores";
import { getRoles } from "@/composable/users/getRoles.js";

const emit = defineEmits(["update:modelValue"]);

defineProps({
  modelValue: {
    default: false,
    type: Boolean,
  },
});

const authStore = useAuthStore();
const auth = useAuth();
const roles = getRoles();
const isSuperAdmin = roles.isSuperAdmin.value;
const isAccountManager = roles.isAccountManager.value;
const isSpecificManager = roles.isSpecificManager.value;
const isMultiStoreManager = roles.isMultiStoreManager.value;

const { cookies } = useCookies();

const isImpersonating = ref(false);

const openModal = () => {
  emit("update:modelValue", true);
};

const stopImpersonating = () => {
  localStorage.removeItem("impersonate_user");
  localStorage.removeItem("impersonate_token");

  let token = JSON.parse(localStorage.getItem("access_token"));
  let user = JSON.parse(localStorage.getItem("user"));

  cookies.set("default_auth_token", token);
  cookies.set("default_auth_user", user.id);
  auth.user(user);
  auth.token(null, token, false);
  authStore.$patch({
    user: user,
    token: token,
  });

  window.location.reload();
};

onMounted(() => {
  if (localStorage.getItem("impersonate_user")) {
    isImpersonating.value = true;
  }
});
</script>
<template>
  <div>
    <span
      v-if="!isImpersonating && (isSuperAdmin || isAccountManager || isSpecificManager || isMultiStoreManager)"
      :class="[
        active ? 'bg-untitled-gray-100' : 'text-untitled-gray-700',
        'block px-4 py-2 text-sm text-untitled-gray-700 hover:cursor-pointer font-medium',
      ]"
      @click="openModal"
    >
      <UsersIcon class="mr-1 h-5 w-5 inline" aria-hidden="true" />
      Switch User
    </span>
    <span
      :class="[
        active ? 'bg-untitled-gray-100' : 'text-untitled-gray-700',
        'block px-4 py-2 text-sm text-untitled-gray-700 hover:cursor-pointer font-medium',
      ]"
      v-if="isImpersonating"
      @click="stopImpersonating"
    >
      <UsersIcon class="mr-1 h-5 w-5 inline" aria-hidden="true" />
      Switch Back
    </span>
  </div>
</template>